// @flow

import ApiService from "../apiService";
import { tokenStore } from "../../stores/auth";

export type Notice = {
  id: Number,
  title: String,
  description: String,
  params: Object,
  type: String,
  users: Array<Number>,
  is_read: Boolean,
  created_at: String,
  updated_at: String,
}

const API_URL = process.env.EMS_API_URL || location.origin;
class Notices {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getSocketUrl() {
    const url = new URL(`${API_URL}/api/ws/notices/?Authorization=${tokenStore.getState()}`);
    url.protocol = "wss:";

    return url.toString();
  }

  createNotice(noticeData: Omit<Notice, "id">) {
    return this.apiService.post('notices', {}, noticeData);
  }

  updateNotice(noticeId, noticeData) {
    return this.apiService.patch('notices', {}, {
      ...noticeData,
      id: noticeId
    });
  }

  deleteNotice(noticeId) {
    return this.apiService.delete(`notices/${noticeId}`);
  }

  getNotices() {
    return this.apiService.get(`notices`);
  }

  readNotice(noticeId) {
    return this.apiService.post(`read-notices`, {}, {
      notice: noticeId
    });
  }

  unreadNotice(noticeId) {
    return this.apiService.delete(`read-notices`, {}, {
      notice: noticeId
    });
  }

  getUnreadNotices() {
    return this.apiService.get(`notices`, {
      unread: "true"
    });
  }

  getUnreadAndReadNotices() {
    return this.apiService.get(`notices`, {
      unread_and_last_read: "true"
    });
  }
}

export default new Notices();
