import apiv2 from "../apiv2";
import { makeAutoObservable } from "mobx";
import _ from "lodash";
import authStore from "./authStore";

class OrganizationsStore {
  organizations = [];
  loadingData = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getOrganizations(isAnonymous) {
    if (!this.organizations.length && !this.loadingData) {
      try {
        this.loadingData = true;
        let response = [];
        if (isAnonymous) {
          response = await apiv2.integrations.getOrganizations();
        } else {
          response = await apiv2.organizations.getAllOrganizations();
        }
        this.organizations = _.sortBy(response, "name");
        this.loadingData = false;
        return this.organizations;
      } catch (e) {
        console.error(e);
        this.loadingData = false;
        return false;
      }
    } else {
      return this.organizations;
    }
  }

  getOrganization(id) {
    return this.organizations.find(item => item.id === id);
  }

  getDoctorOrganizations() {
    const organizationsDoctorIds = authStore.user?.doctor?.organizations;
    return this.organizations.filter(item => organizationsDoctorIds.includes(item.id));
  }

  /** С переносом клиники Рассвет из города "Москва Рассвет" с id 19 в город "Москва" с id 12 */
  get groupedOrganizationByCity() {
    return this.getDoctorOrganizations().reduce((acc, organization) => {
      if (!organization.city) {
        return acc;
      }

      let cityId = organization.city.id === 19 ? 12 : organization.city.id;

      if (!acc[cityId]) {
        acc[cityId] = [];
      }

      acc[cityId].push(organization);
      return acc;
    }, {});
  }

  getCities() {
    return this.organizations.reduce((cities, item) => {
      if (!cities.find((city) => city.id === item.city.id)) {
        if (item.city.id !== 19) {
          cities.push(item.city);
        }
      }
      return cities;
    }, [])
  }

  getCities() {
    return this.organizations.reduce((cities, item) => {
      if (!cities.find((city) => city.id === item.city.id)) {
        if (item.city.id !== 19) {
          cities.push(item.city);
        }
      }
      return cities;
    }, [])
  }
}

export default new OrganizationsStore();
