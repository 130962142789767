export const getFullNameUser = ({ last_name = "", first_name = "", middle_name = "" }) => {
  return `${last_name} ${first_name} ${middle_name}`;
};

export const getLastNameWithInitials = fullName => {
  if (!fullName) {
    return "";
  }

  const splitFullName = fullName.split(" ");

  if (splitFullName.length < 3) {
    return fullName;
  }

  return `${splitFullName[0]} ${splitFullName[1][0] + ". "}${splitFullName[2][0] + "."}`;
};

export const getInitials = ({ lastName = "", firstName = "" }) => {
  return `${lastName.slice(0, 1)}${firstName.slice(0, 1)}`;
};
