import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class NewGeneralSchedule {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getSchedule({
    start_date,
    end_date,
    organization_ids,
    doctor_ids,
    patient_id,
    service_ids,
    type,
    online,
    opinion,
    is_dms_accepts
  }) {
    return this.apiService.get(
      `new-schedule`,
      {
        start_date,
        end_date,
        organization_ids,
        doctor_ids,
        patient_id,
        service_ids,
        type,
        online,
        opinion,
        is_dms_accepts
      },
      {},
      true,
      "",
      false,
      true
    );
  }

  getScheduleBoard({
    start_date,
    end_date,
    organization_ids,
    doctor_ids,
    patient_id,
    service_ids,
    type,
    online,
    opinion,
    is_dms_accepts
  }) {
    return this.apiService.get(
      `schedule-board`,
      {
        start_date,
        end_date,
        organization_ids,
        doctor_ids,
        patient_id,
        service_ids,
        type,
        online,
        opinion,
        is_dms_accepts
      },
      {},
      true,
      "",
      false,
      true
    );
  }

  getServices({ name, organization_ids, doctor_ids, speciality_ids }) {
    return this.apiService.get(`new-schedule/services`, {
      name,
      organization_ids,
      doctor_ids,
      speciality_ids
    });
  }

  getSpecialities({ organization_ids }) {
    return this.apiService.get(`new-schedule/specialities`, {
      organization_ids
    });
  }

  getDoctors({
    start_date,
    end_date,
    full_name,
    organization_ids,
    services_ids,
    type,
    online,
    opinion,
    is_dms_accepts
  }) {
    return this.apiService.get(
      `new-schedule/doctors`,
      {
        start_date,
        end_date,
        full_name,
        organization_ids,
        services_ids,
        type,
        online,
        opinion,
        is_dms_accepts
      },
      {},
      true,
      "",
      false,
      true
    );
  }

  getPatients({ name, organization_ids }) {
    return this.apiService.get(`new-schedule/patients`, {
      full_name: name,
      organization_ids
    });
  }

  getDoctorServices({ doctorId, organizationIds }) {
    return this.apiService.get(`new-schedule/doctor/${doctorId}/services`, {
      organization_ids: organizationIds
    });
  }

  postRecordScheduleInserts(params) {
    return this.apiService.post("schedule-inserts-v2", {}, { ...params });
  }

  postCancelScheduleInserts(params) {
    return this.apiService.post(
      "integration/schedule/cancel",
      {},
      { ...params },
      { Secretkey: "secret" }
    );
  }
}

export default new NewGeneralSchedule();
