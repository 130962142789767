import ApiService from "../apiService";
import { getFullNameUser } from "../../utils/usernameConversion";

const API_URL = process.env.EMS_API_URL || location.origin;
class FDOC {
  apiService;
  constructor() {
    this.apiService = new ApiService('api-v2/fdoc', null, null, API_URL);
  }

  getDocumentsByAppointment(appointmentId) {
    return this.apiService.get(`documents/${appointmentId}`);
  }

  getDocumentLink(data) {
    const { appointmentId, packageId } = data;

    return this.apiService.get(`generate_url/${appointmentId}/${packageId}`);
  }

  uploadDocuments(data) {
    const { appointmentId, fileName, file, patient } = data;

    return this.apiService.post(`upload_documents/${appointmentId}`, {}, {
      "documents": [
        {
          "name": fileName,
          "file": file
        }
      ],
      "package":{
        "needIdentification": true,
        "identificationType": "esia",
        "signers": [
          {
            "type": "corp",
            "priority": 1,
            "operatorAutoSign": false
          },
          {
            "id": patient.id,
            "name": getFullNameUser(patient),
            "phone": patient.phone_number,
            "type": "client",
            "priority": 2,
            "operatorAutoSign": false
          }
        ]
      }
    });
  }
}

export default new FDOC();
