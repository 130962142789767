import React from 'react';
import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from "uuid";
import moment from "moment";
import apiv2 from "../apiv2";
import { tokenStore } from "./auth";
import voximplant from "../components/chats/video/voximplantConference";
import { CallEvents, EndpointEvents, Hardware } from "voximplant-websdk";
import { useToast } from "../components/toast/useToast";
import { getBase64 } from '../utils/getBase64';
import _ from "lodash";
import authStore from './authStore';
import { history } from '../utils/history';
import sendNotification from "../utils/sendNotification";
import {socketErrorHandler} from '../utils/sockerErrorHandler';
import {captureEventToSentry, captureExceptionToSentry} from '../utils/captureToSentry';
import { applicationFeatureEnabled, FEATURE } from "../utils/applicationFeatures";

const toastManager = useToast();

const MessageType = {
  NEW: "new_message",
  DELETE: "delete_message",
  UPDATE: "update_message",
  LOAD_FILE: "load_file",
  NEW_ATTACHMENT: "new_attachment",
  CHANGE_CHAT_STATUS: "chat_status",
  NEW_SCHEDULE_INSERT: "new_schedule_insert",
  CONNECTION_CLOSE: "close_connection",
  PONG: "pong",
  SEND_IS_ONLINE: "send_is_online"
};

export const ChatTypes = {
  CHAT: "chat",
  FOLDER: "folder"
};

export const MessageStatus = {
  SENDING: "sending",
  SENT: "sent",
  ERROR: "error"
};

export const ChatMessageType = {
  ONLY_FOR_PATIENT: "only_for_patient",
  APPOINTMENT: "appointment",
  TEXT: "text",
  IMAGES: "images",
  IMAGE: "image",
  FILE: "file",
  SYSTEM: "system",
};

export const ChatFilters = {
  ALL: "all",
  NOT_READ: "not_read",
  OPENED: "opened",
  CLOSED: "closed",
  PAID: "paid"
};

export const FileMessageTypes = {
  IMAGE: "image_message",
  FILE: "file_message"
}

class ChatStore {
  socket = null;
  chats = [];
  chatsData = {};
  currentChatId = 0;
  currentCallId = 0;
  chatsPagination = {
    perPage: 20,
    currentPage: 1,
    nextPage: 1,
  };
  unreadChatsCount = 0;
  updateUnreadChatsCountTimeout = 0;
  isChatsLoading = false;
  isChatsLoaded = false;
  isAnonymousMode = false;
  allowAnonymousMode = false;
  callSettingsScreenShowed = false;
  loadingChatMessages = false;
  chatIsReadingNow = false;
  messagesPerPage = 20;
  currentDevices = {
    cameraId: 0,
    speakerId: 0,
    micId: 0
  };
  lastSelectedDevices = {
    cameraId: 0,
    speakerId: 0,
    micId: 0
  };
  videoEnabled = true;
  videoStateChangeInProgress = false;
  videoMinimized = false;
  voxCall = null;
  voxCallStats = {};
  voxAndDevicesInitializationStarted = false;
  voxAndDevicesInitialized = false;
  callOpponent = null;
  endpoints = [];
  voiceActive = {};
  microphoneMuted = false;
  microphoneMuteInProgress = false;
  videoDevices = [];
  audioDevices = [];
  outputDevices = [];
  audioContext = null;
  audioWavesTracks = null;
  events = {
    AudioLevelChange: "audio-level-change"
  };
  eventListeners = {
    "audio-level-change": []
  };
  searchText = '';
  selectedFilter = ChatFilters.ALL;

  isCallOpenChatConfirmShowed = false;
  showMediaPermissionNotDeviceModal = false;
  showMediaPermissionSelectModal = false;
  showMediaPermissionLockModal = false;
  showVoximplantConnectionErrorModal = false;
  voximplantIsReconnecting = false;
  disabledVideoButton = false;
  isShowSettingChatsModal = false;

  constructor() {
    makeAutoObservable(this);
    this._recalcEnpoints = this._recalcEnpoints.bind(this);
    this.searchChats = this.searchChats.bind(this);
    this.setVoximplantIsReconnecting = this.setVoximplantIsReconnecting.bind(this);
    this.showVoximlpantErrorModal = this.showVoximlpantErrorModal.bind(this);
    this.closeVoximplantErrorModal = this.closeVoximplantErrorModal.bind(this);
  }

  delay(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time)
    })
  }

  get isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
  }

  setVoximplantIsReconnecting(value) {
    this.voximplantIsReconnecting = value;
  }

  showVoximlpantErrorModal() {
    this.showVoximplantConnectionErrorModal = true;
  }

  closeVoximplantErrorModal() {
    this.showVoximplantConnectionErrorModal = false;
  }

  *initDevices(isOnlyAudio) {
    if (!this.voxAndDevicesInitialized) {
      try {
        this.showMediaPermissionSelectModal = true;
        this.voxAndDevicesInitializationStarted = true;
        const params = {
          audio: true,
        }
        if (!isOnlyAudio) {
          params['video'] = true;
        }
        const mediaStream = yield navigator.mediaDevices.getUserMedia(params);
        this.showMediaPermissionSelectModal = false;
        const tracks = mediaStream.getTracks();
        tracks.forEach(track => {
          track.stop()
        });
        try {
          yield voximplant.getInstance();
        } catch(e) {
          this.voxAndDevicesInitializationStarted = false;
          console.error(e);
          return false;
        }
        yield this.delay(100);
        if (!isOnlyAudio) {
          const cameraManager = Hardware.CameraManager.get();
          this.videoDevices = yield cameraManager.getInputDevices();
          this.setCameraId(this.videoDevices.length ? this.videoDevices[0].id : 0);
        }
        const audioManager = Hardware.AudioDeviceManager.get();
        this.audioDevices = yield audioManager.getInputDevices();
        this.outputDevices = yield audioManager.getOutputDevices();
        this.setSpeakerId(this.outputDevices.length ? this.outputDevices[0].id : 0);
        this.setMicId(this.audioDevices.length ? this.audioDevices[0].id : 0);
      } catch (e) {
        if (!isOnlyAudio) {
          this.showMediaPermissionSelectModal = false;
          this.voxAndDevicesInitializationStarted = false;
          this.videoEnabled = false;
          this.disabledVideoButton = true;
          return yield this.initDevices(true);
        }
        if (e.toString() === 'NotAllowedError: Permission denied by system' || e.toString().includes('NotFoundError')  || e.toString() === 'NotReadableError: Failed to allocate videosource') {
          this.showMediaPermissionSelectModal = false;
          this.voxAndDevicesInitializationStarted = false;
          this.showMediaPermissionNotDeviceModal = true;
          return false;
        }
        this.showMediaPermissionSelectModal = false;
        this.voxAndDevicesInitializationStarted = false;
        this.showMediaPermissionLockModal = true;
        return false;
      }
    } else {
      this.showMediaPermissionSelectModal = false;
      this.voxAndDevicesInitializationStarted = false
      this.showMediaPermissionLockModal = true;
      return false;
    }
    this.showMediaPermissionSelectModal = false;
    this.voxAndDevicesInitialized = true;
    return true;
  }

  setLastSelectedDevices() {
    this.setCameraId(this.lastSelectedDevices.cameraId);
    this.setSpeakerId(this.lastSelectedDevices.speakerId);
    this.setMicId(this.lastSelectedDevices.micId);
  }

  resetCurrentDevices() {
    this.currentDevices.cameraId = 0;
    this.currentDevices.speakerId = 0;
    this.currentDevices.micId = 0;
  }

  _detectAudioWorklet() {
    if (window["OfflineAudioContext"]) {
      const context = new window["OfflineAudioContext"](1, 1, 44100);
      return context.audioWorklet && typeof context.audioWorklet.addModule === "function";
    }
    return false;
  }

  *prepareAudioNodes(track) {
    const oldContext = this.audioContext;
    if (oldContext) yield oldContext.close();

    const audioContext = new AudioContext();
    yield audioContext.audioWorklet.addModule(
      `data:application/javascript;charset=utf8,${encodeURIComponent(`
      class AudioLevelProcessor extends AudioWorkletProcessor {
        constructor() {
          super();
          this.cycle = 0;
          this.fullValue = 0;
        }
        process(inputs, outputs, parameters) {
          this.cycle++;
          const sum = inputs[0].reduce((pred, item) => {
            const fItem = parseFloat(item);
            return pred + fItem * fItem;
          }, 0.0);
          this.fullValue += Math.sqrt(sum);
          if (this.cycle === 10) {
            this.port.postMessage({
              level: this.fullValue / this.cycle,
            });
            this.cycle = 0;
            this.fullValue = 0;
          }
          return true;
        }
      }
      
      registerProcessor('audio-level-processor', AudioLevelProcessor);
    `)}`
    );
    const audioLevelNode = new AudioWorkletNode(audioContext, "audio-level-processor");
    audioLevelNode.port.onmessage = event => {
      if (this.eventListeners[this.events.AudioLevelChange].length) {
        this.eventListeners[this.events.AudioLevelChange].forEach(callback => {
          callback(event);
        });
      }
    };
    const micNode = audioContext.createMediaStreamSource(new MediaStream([track]));
    micNode.connect(audioLevelNode);
    audioLevelNode.connect(audioContext.destination);
    return audioContext;
  }

  *initAudioWaves() {
    const constraints = {};
    if (this.currentDevices.micId !== 0) {
      constraints.audio = {
        deviceId: this.currentDevices.micId
      };
    } else {
      constraints.audio = true;
    }

    const media = yield navigator.mediaDevices.getUserMedia(constraints);
    this.audioWavesTracks = media.getAudioTracks();

    if (this._detectAudioWorklet()) {
      this.audioContext = yield this.prepareAudioNodes(this.audioWavesTracks[0]);
    }
  }

  stopAudioWaves() {
    if (this.audioContext) {
      this.audioWavesTracks.forEach(track => track.stop());
      this.audioContext.close();
      this.audioContext = null;
    }
  }

  on(event, callback) {
    if (this.eventListeners[event]) {
      this.eventListeners[event].push(callback);

      return () => {
        this.eventListeners[event] = this.eventListeners[event].filter(cb => cb !== callback);
      };
    }
  }

  *showCallSettingsScreen() {
    // врач Куваев(первый врач телемеда) не закрывает чаты после созвона, поэтому и отрывать заставлять не будем
    // if (this.currentChat && this.currentChat.opponent?.patient_id && !this.currentChat.can_patient_chat) {
    //   return this.showCallOpenChatConfirm();
    // }
    if (!this.audioDevices.length) {
      const deviceInitializationResult = yield this.initDevices();
      if (!deviceInitializationResult) {
        return;
      }
    } else {
      this.setLastSelectedDevices();
    }
    if (!this.showMediaPermissionNotDeviceModal && !this.showMediaPermissionLockModal) {
      this.callSettingsScreenShowed = true;
    }
  }

  closeCallSettingsScreen() {
    this.callSettingsScreenShowed = false;
  }

  changeShowMediaPermissionNotDeviceModal() {
    this.showMediaPermissionNotDeviceModal = !this.showMediaPermissionNotDeviceModal;
  }

  changeShowMediaPermissionLockModal() {
    this.showMediaPermissionLockModal = !this.showMediaPermissionLockModal;
  }

  changeOnline(chatId, online, timestamp) {
    const chat = this.findChat(chatId);
    chat.is_online = online;
    chat.last_date_online = timestamp.replace(" ", "T");
    this._updateChats();
  }

  fixSocketMessage(message) {
    if (message.data?.message_type === ChatMessageType.IMAGE) {
      message.data.type = ChatMessageType.IMAGE;
      message.data.type_message = FileMessageTypes.IMAGE;
    }
    if (message.data?.message_type === ChatMessageType.FILE) {
      message.data.type = ChatMessageType.FILE;
      message.data.type_message = FileMessageTypes.FILE;
    }

    return message;
  }

  initWS() {
    if (
      this.socket && (
        this.socket.readyState === WebSocket.CONNECTING ||
        this.socket.readyState === WebSocket.OPEN
      )
    ) {
      return;
    }

    const url = new URL(`${process.env.EMS_API_URL || location.origin}/api/ws/messages/?Authorization=${tokenStore.getState()}`);
    url.protocol = "wss:";
    this.socket = new WebSocket(url.toString());
    this.socket.onopen = () => {
      console.log("connection to the channel has been established");
    };

    this.socket.onclose = socketErrorHandler(this.socket, () => this.initWS());

    this.socket.onmessage = event => {
      const message = JSON.parse(event.data);
      console.log("new ws message", message);

      if (message?.code === "unauthorized") {
        // const refreshAndSaveToken = async () => {
        //     const newToken = connectionData.token ? await refreshToken(connectionData.token) : '';
        //     dispatch(userActions.tokenRefreshed({token: newToken}));
        // };
        //
        // refreshAndSaveToken();
        return;
      }

      if (message.type === MessageType.CONNECTION_CLOSE) {
        // if (message.reason === 'new_login') {
        //     Alert.alert('', 'На Ваш аккаунт был произведен вход с нового устройства.');
        //     forceLogout();
        // }
        //
        // if (message.reason === 'timeout error') {
        //     setNeedToReconnect(prev => prev + 1);
        // }
      }

      if (message.type === MessageType.PONG) {
        // playPingPong(false);
      }

      if (message?.data?.status === "fail") {
        console.log("WS FAIL!!!");
        return;
      }

      switch (message.type) {
        case MessageType.SEND_IS_ONLINE: {
          const data = message.data;
          this.changeOnline(data.sender_id, data.online, data.timestamp);
          break;
        }
        case MessageType.CHANGE_CHAT_STATUS: {
          const chatToUpdate = this.findChat(message.data.chatroom_id);
          if (!chatToUpdate) {
            return;
          }
          chatToUpdate.can_patient_chat = message.data.can_patient_chat;
          break;
        }

        case MessageType.NEW: {
          const fixedMessage = this.fixSocketMessage(message);
          const needSendNotification = fixedMessage.data.sender?.id !== authStore.user.id
          this.checkChatAndAddMessage(fixedMessage.data, fixedMessage.data.receiver_id, needSendNotification);
          break;
        }
        case MessageType.UPDATE: {
          const fixedMessage = this.fixSocketMessage(message);

          this.checkChatAndUpdateMessage(fixedMessage.data, fixedMessage.data.receiver_id);
          // handleUpdateMessage(message.data);
          break;
        }
        case MessageType.DELETE: {
          this.deleteMessageByOpponent(message.data, message.data.sender);
          break;
        }

        case MessageType.NEW_SCHEDULE_INSERT: {
          // const scheduleItemData: ChangeScheduleItemPayload =
          //     message.data as unknown as ChangeScheduleItemPayload;
          // handleNewScheduleItem({data: scheduleItemData});
          break;
        }

        case MessageType.LOAD_FILE: {
          if (message.data.type === "load_file_result") {
            const messageToEdit = this.findMessageInChat(message.data.receiver, message.data.message_id, message.data.message_uuid);
            const attachment = this._createMessageAttachmentFromData(message.data);
            if (!messageToEdit) {
              this._createMessageWithAttachments(message.data, [attachment], ChatMessageType.FILE, FileMessageTypes.IMAGE, MessageStatus.SENT);
              return;
            }
            messageToEdit.id = message.data.message_id;
            messageToEdit.uuid = message.data.message_uuid;
            // messageToEdit.attachments.push(attachment); // Больше не нужно, потому что файлы мы фактически обновляем у себя сами, когда заканчивается процесс загрузки.
            messageToEdit.data.attachments_number = messageToEdit.attachments.length;
            if (this.currentChatId === message.data.receiver) {
              this.updateMessageInChat({ ...messageToEdit }, message.data.receiver);
            }
          }
          if (this.currentChatId === message.data.receiver) {
            this._updateCurrentChatMessages();
          }
          break;
        }
        case MessageType.NEW_ATTACHMENT: {
          // handleNewAttachment(message.data);
          break;
        }
        default:
          break;
      }
    };
  }

  get unreadMessagesCount() {
    return this.chats.length ? this.chats.reduce((acc, chat) => acc + chat.unread_messages_count, 0) : 0;
  }

  _updateUnreadMessagesCountInChat(chat) {
    chat.unread_messages_count += 1;
  }

  setMessageText(text, setPrevText) {
    if (setPrevText) {
      this.currentChatData.prevText = this.currentChatData.text;
    }
    this.currentChatData.text = text;
  }

  get currentMessageText() {
    return this.currentChatData?.text || "";
  }

  goToChat(chatroomId, needStartCall) {
    if (chatroomId) {
      history.push(`/chats/${chatroomId}${needStartCall ? '?startCall=true' : ''}`);
    }
  }

  *goToChatFromSchedule(schedule, needStartCall) {
    let roomId;

    // ToDo {6618} если все будет ОК, фичу можно будет удалить
    if (schedule.online_link && applicationFeatureEnabled(FEATURE.CHANGED_CHATROOM_CALCULATION)) {
      const separator = schedule.online_link.slice(0, -1).split("/");
      const callId = separator[separator.length - 1];
      const response = yield apiv2.call.get(callId);
      roomId = response.room;
    } else {
      const response = yield apiv2.chatroom.getByScheduleId(schedule.id);
      roomId = response.id;
    }

    if (!roomId) {
      return;
    }
    this.goToChat(roomId, needStartCall);
  }

  *goToChatWithPatient(patientId, needStartCall) {
    const chatroom = yield apiv2.chatroom.getByPatientId(patientId, true);
    if (!chatroom) {
      return;
    }
    this.goToChat(chatroom.id, needStartCall);
  }

  chatHaveMessage(chatId, messageId) {
    if (this.chatsData[chatId]) {
      return this.chatsData[chatId].messages.some(chatMessage => chatMessage.id === messageId);
    }
    return false;
  }

  setUnreadChatsCount(count) {
    this.unreadChatsCount = count > 0 ? count : 0;
  }

  updateUnreadChatsCount() {
    if (this.updateUnreadChatsCountTimeout) {
      clearTimeout(this.updateUnreadChatsCountTimeout);
    }
    this.updateUnreadChatsCountTimeout = setTimeout(async () => {
      const response = await apiv2.chatroom.getUnreadChatsCount();
      this.setUnreadChatsCount(response.count_unread_chat_rooms)
    }, 500);
  }

  *addMessage(message, chatId, needSendNotification) {
    this._checkAndCreateChatData(chatId);
    const chat = this.chats.splice(this.chats.findIndex(chat => chat.id === chatId), 1)[0];
    if (chat) {
      this._prepareMessage(message);
      this._checkAndPrepareAttachmentMessage(message);
      if (message.type !== ChatMessageType.SYSTEM) {
        chat.last_message = message;
      }
      if (message.sender?.id !== authStore.user.id) {
        this._updateUnreadMessagesCountInChat(chat);
      }
      this.chats.unshift(chat);
      if (this.chatHaveMessage(chat.id, message.id)) {
        this.updateMessageInChat(message, chatId);
      } else {
        this.chatsData[chatId].messages.push(message);
      }
    }
    if (needSendNotification && message.sender) {
      sendNotification({
        id: message.id,
        data: { id: message.receiver_id },
        title: `${message.sender.first_name} ${message.sender.last_name}`,
        body: message.message_type === ChatMessageType.IMAGE
          ? 'Прислал картинку'
          : message.message_type === ChatMessageType.FILE
            ? 'Прислал файл'
            : message.text,
      });
    }
    this.updateUnreadChatsCount();
  }

  updateMessageInChat(message, chatId) {
    this.chatsData[chatId].messages = this.chatsData[chatId].messages.map((chatMessage) => {
      if (chatMessage.id === message.id || chatMessage.id === message.uuid) {
        return {
          ...chatMessage,
          ...message
        };
      }
      return chatMessage;
    });
  }

  deleteMessageByOpponent(data, initiator) {
    if (initiator.id === authStore.user.id) {
      return;
    }
    if (this.chatsData[data.receiver_id]) {
      this.chatsData[data.receiver_id].messages = this.chatsData[data.receiver_id].messages.filter((message) => message.id !== data.id);
    }
    const chat = this.chats.find(chat => chat.id === data.receiver_id);
    if (chat && data.next_last_message) {
      chat.last_message = data.next_last_message;
      if (!data.is_read && !!chat.unread_messages_count) {
        chat.unread_messages_count--;
      }
    }
    this.updateUnreadChatsCount();
  }

  localUpdateMessage(message, chatId) {
    this._checkAndCreateChatData(chatId);
    const chat = this.chats.splice(this.chats.findIndex(chat => chat.id === chatId), 1)[0];
    if (chat) {
      this._prepareMessage(message);
      this._checkAndPrepareAttachmentMessage(message);
      if (chat.last_message && chat.last_message.id === message.id) {
        chat.last_message = message;
      }
      this.chats.unshift(chat);
      this.updateMessageInChat(message, chatId);
    }
  }

  *checkChatAndAddMessage(message, chatId, needSendNotification) {
    if (!this.hasChat(chatId)) {
      yield this.loadChatById(chatId);
    }

    this.addMessage(message, chatId, needSendNotification);
  }

  *checkChatAndUpdateMessage(message, chatId) {
    if (!this.hasChat(chatId)) {
      yield this.loadChatById(chatId);
    }

    this.localUpdateMessage(message, chatId);
  }

  _checkAndCreateChatData(chatId) {
    if (!this.chatsData[chatId]) {
      this.chatsData[chatId] = { messages: [], text: "", prevText: "", editingMessageId: 0, firstMessagesFetched: false };
    }
  }

  addMessages(messages, chatId) {
    this._checkAndCreateChatData(chatId);

    const toAddMessagesIds = messages.map(message => message.id);
    const currentMessagesIds = this.chatsData[chatId].messages.map(message => message.id);
    const newMessagesIds = _.difference(toAddMessagesIds, currentMessagesIds);

    if (newMessagesIds.length) {
      const filteredNewMessages = this._prepareMessages(messages.filter(message => newMessagesIds.includes(message.id)));
      this.chatsData[chatId].messages = [...filteredNewMessages, ...this.chatsData[chatId].messages];
    }
  }

  get currentChatMessages() {
    return this.currentChatData?.messages?.filter((message) => message._type !== ChatMessageType.ONLY_FOR_PATIENT) || [];
  }

  _getMessageType(message) {
    if (message.data?.only_for_patient) {
      return ChatMessageType.ONLY_FOR_PATIENT;
    }
    if (message.type === ChatMessageType.SYSTEM && message.data?.type === ChatMessageType.APPOINTMENT) {
      return ChatMessageType.APPOINTMENT;
    }
    if (message.type === ChatMessageType.FILE && message.type_message === FileMessageTypes.FILE && message.attachments.length) {
      return ChatMessageType.FILE;
    }
    if (message.type === ChatMessageType.FILE && message.type_message === FileMessageTypes.IMAGE && message.attachments.length) {
      const firstAttachment = message.attachments[0];
      if (!firstAttachment.image_140x120 && !firstAttachment.image_289x300) {
        return ChatMessageType.FILE;
      }
      return ChatMessageType.IMAGE;
    }
    if (message.type === ChatMessageType.IMAGE && message.type_message === FileMessageTypes.IMAGE && message.attachments.length) {
      return ChatMessageType.IMAGE;
    }
    return ChatMessageType.TEXT;
  }

  _prepareMessage(message) {
    message._type = this._getMessageType(message);
    message._status = message._status || MessageStatus.SENT;
    if (message.receiver_id) {
      message.receiver = message.receiver_id;
    } else {
      message.receiver_id = message.receiver;
    }
  }

  _prepareMessages(messages) {
    messages.forEach(message => {
      this._prepareMessage(message);
      this._checkAndPrepareAttachmentMessage(message);
    });
    return messages;
  }

  _setChatMessagesPaginationData(pagination) {
    this._checkAndCreateChatData(this.currentChatId);
    if (!pagination.next_page) {
      this.currentChatData.allMessagesLoaded = true;
    } else {
      this.currentChatData.nextPage = pagination.next_page;
    }
  }

  async getCurrentChatMessages(page) {
    try {
      runInAction(() => {
        this.loadingChatMessages = true;
      });
      const response = await apiv2.chat.getMessages(
        this.currentChatId,
        this.messagesPerPage,
        page
      );
      this._setChatMessagesPaginationData(response.pagination);
      return response.results;
    } catch(e) {
      console.log(e);
      toastManager.showToast(
        <div>
          При загрузке сообщений чата с ID [{this.currentChatId}] произошла неизвестная ошибка, вы можете написать в поддержку, сообщив код ошибки: {e.code}
        </div>,
        {
          duration: 20000
        }
      );
      return [];
    } finally {
      runInAction(() => {
        this.loadingChatMessages = false;
      });
    }
  }

  *loadCurrentChatMessages() {
    if (!this.chats.length) {
      return;
    }
    if (this.currentChatData && this.currentChatData.firstMessagesFetched) {
      return;
    }
    if (this.loadingChatMessages) {
      return;
    }
    const currentChatId = this.currentChatId;
    const newMessages = yield this.getCurrentChatMessages(1);
    this.addMessages(newMessages.reverse(), currentChatId);
    this.chatsData[currentChatId].firstMessagesFetched = true;
  }

  *fetchCurrentChatMessages() {
    if (this.loadingChatMessages) {
      return;
    }
    if (this.currentChatData.allMessagesLoaded) {
      return;
    }
    if (!this.currentChatData.firstMessagesFetched) {
      yield this.loadCurrentChatMessages();
      return;
    }
    const currentChatId = this.currentChatId;
    const newMessages = yield this.getCurrentChatMessages(this.currentChatData.nextPage);
    this.addMessages(newMessages.reverse(), currentChatId);
  }

  _getSenderObjectFromCurrentUser() {
    return {
      id: authStore.user.id || "",
      avatar: authStore.user.hex_image || "",
      first_name: authStore.user.doctor?.first_name || "",
      middle_name: authStore.user.doctor?.middle_name || "",
      last_name: authStore.user.doctor?.last_name || "",
    };
  }

  *sendMessage(text) {
    if (!text) {
      return;
    }
    if (this.editingMessage) {
      this.setMessageText(this.currentChatData.prevText);
      this.currentChatData.prevText = "";
      this.updateMessage(this.editingMessage.id, text);
      this.setEditingMessage(0);
      return;
    }
    this.setMessageText("");
    const data = {
      receiver_id: this.currentChatId,
      receiver_model_name: "ChatRoom",
      text: text
    };
    try {
      const response = yield apiv2.chat.sendMessage(data);
      this.addMessage(response, this.currentChatId);
    } catch(e) {
      this._createErrorTextMessage(text);
      return;
    }
    this.readAllMessagesInChat(this.currentChatId);
  }

  *resendMessage(message) {
    const messageType = this._getMessageType(message);
    if (messageType === ChatMessageType.TEXT) {
      const text = message.text;

      yield this._deleteMessage(message.id);
      yield this.sendMessage(text);
    }
    if (messageType === ChatMessageType.FILE || messageType === ChatMessageType.IMAGE) {
      const attachments = [...message.attachments].map(attachment => attachment.original_file);
      const typeOfMessage = message.type;

      yield this._deleteMessage(message.id);
      yield this._sendFiles(attachments, typeOfMessage);
    }
  }

  _updateCurrentChatMessages() {
    this.currentChatData.messages = [...this.currentChatData.messages];
  }

  findChat(chatId) {
    if (!chatId) {
      return null;
    }
    return this.chats.find(chat => chat.id === chatId) || null;
  }

  findMessageInChat(chatId, messageId, messageUuid = "") {
    if (!this.chatsData[chatId]) {
      return null;
    }
    return this.chatsData[chatId].messages.find(message => message.id === messageId) || this.chatsData[chatId].messages.find(message => message.id === messageUuid || message.uuid === messageUuid) || null;
  }

  isChatMessageRead(chatId, messageId) {
    const message = this.findMessageInChat(chatId, messageId);
    if (!message) {
      return false;
    }
    return message.is_read;
  }

  messageSenderIsDoctor(chatId, messageId) {
    const message = this.findMessageInChat(chatId, messageId);
    if (!message) {
      return false;
    }
    return message.sender?.id === authStore.user?.id;
  }

  *updateMessage(id, text) {
    const message = this.currentChatMessages.find(m => m.id === id);
    message.text = text;
    this._updateCurrentChatMessages();
    try {
      yield apiv2.chat.updateMessage(id, text);
    } catch(e) {
      toastManager.showToast(
        <div>
          Не удалось обновить сообщение с ID [{id}] произошла неизвестная ошибка, вы можете написать в поддержку, сообщив код ошибки: {e.code}
        </div>,
        {
          duration: 20000
        }
      );
    }
  }

  updateInChatMessageStatus(chatId, message, status) {
    this.updateMessageInChat({
      ...message,
      _status: status
    }, chatId);
  }

  async _createAttachmentsFromFiles(files, type) {
    return Promise.all(
      files.map(async (file) => {
        const base64Image = type === ChatMessageType.IMAGE ? await getBase64(file) : '';

        return {
          image_140x120: base64Image,
          image_289x300: base64Image,
          orientation: "",
          original_url: base64Image,
          filename: file.name,
          original_file: file,
          progress: 0,
          loaded: false,
          uuid: uuid()
        };
      })
    );
  }

  _updateMessageAttachment(chatId, messageUuid, attachmentId, attachmentData) {
    const message = this.findMessageInChat(chatId, null, messageUuid);
    if (message && message.attachments) {
      message.attachments = message.attachments.map(attachment => {
        if (attachment.uuid === attachmentId) {
          attachment = {...attachment, ...attachmentData};
        }
        return attachment;
      });
      this.updateInChatMessageStatus(
        chatId,
        message,
        message.attachments.every(attachment => attachment.loaded) ? MessageStatus.SENT : MessageStatus.SENDING
      );
    }
  }

  *_sendFiles(files, type) {
    const currentChatId = this.currentChatId;
    const messageUUID = uuid();
    const sendMessageData = {
      receiver: currentChatId,
      message_uuid: messageUUID,
      message_type: type,
      receiver_model_name: "ChatRoom",
    };
    const messageData = {
      created_at: new Date().toISOString(),
      id: messageUUID,
      is_changed: false,
      is_read: false,
      receiver: currentChatId,
      sender: this._getSenderObjectFromCurrentUser(),
    };
    const typeMessage = type === ChatMessageType.FILE ? FileMessageTypes.FILE : FileMessageTypes.IMAGE;
    const attachments = yield this._createAttachmentsFromFiles(files, type);

    this._createMessageWithAttachments(
      messageData,
      attachments,
      type,
      typeMessage,
      MessageStatus.SENDING
    );
    this._updateCurrentChatMessages();

    try {
      yield apiv2.messageAttachments.send(sendMessageData, attachments, (attachmentUuid, percent) => {
        this._updateMessageAttachment(currentChatId, messageUUID, attachmentUuid, {
          progress: percent,
          loaded: percent >= 100
        });
      });
    } catch(e) {
      // TODO: Сообщение уже есть, если словили ошибку, надо менять его статус на ошибка отправки

      this.updateInChatMessageStatus(currentChatId, messageData, MessageStatus.ERROR);
    }
  }

  _createErrorTextMessage(text) {
    const messageUUID = uuid();
    const errorMessageData = {
      id: messageUUID,
      uuid: messageUUID ,
      sender: this._getSenderObjectFromCurrentUser(),
      receiver: this.currentChatId,
      attachments: [],
      text: text,
      type: ChatMessageType.TEXT,
      type_message: "text_message",
      _type: ChatMessageType.TEXT,
      _status: MessageStatus.ERROR,
    };
    this.addMessage(errorMessageData, this.currentChatId);
  }

  _createMessageAttachmentFromData(data) {
    return {
      image_140x120: data.image_140x120,
      image_289x300: data.image_289x300,
      orientation: data.orientation,
      original_url: data.original_url,
      uuid: data.uuid,
      progress: 100,
      loaded: true
    };
  }

  _getAttachmentMessageTextByType(type) {
    if (type === FileMessageTypes.FILE) {
      return '📄 Файл';
    }
    if (type === FileMessageTypes.IMAGE) {
      return '🖼️ Изображение';
    }

    return '';
  }

  _checkAndPrepareAttachmentMessage(message) {
    if ((
      message.type_message === FileMessageTypes.FILE ||
      message.type_message === FileMessageTypes.IMAGE
    ) && !message.text) {
      message.text = this._getAttachmentMessageTextByType(message.type_message);
    }
  }

  _createMessageWithAttachments(data, attachments, type, typeMessage, status) {
    const messageData = {
      attachments: attachments,
      created_at: data.created_at || new Date().toISOString(),
      data: {attachments_number: attachments.length},
      id: data.message_id || data.id,
      is_changed: data.is_changed,
      is_read: data.is_read,
      receiver: data.receiver,
      sender: data.sender,
      text: null,
      type: type,
      type_message: typeMessage
    };
    messageData._status = status;
    this.addMessage(messageData, data.receiver, false);
  }

  _filesContainIllegalType(files) {
    const checkRegexp = /(\.|\/)(bat|exe|cmd|sh|php([0-9])?|pl|cgi|386|dll|com|torrent|js|app|jar|pif|vb|vbscript|asp|cer|csr|jsp|drv|sys|ade|adp|bas|chm|cpl|crt|csh|fxp|hlp|hta|inf|ins|isp|jse|htaccess|htpasswd|ksh|lnk|mdb|mde|mdt|mdw|msc|msi|msp|mst|ops|pcd|prg|reg|scr|sct|shb|shs|url|vbe|vbs|wsc|wsf|wsh)$/i;
    return [...files].some(file => {
      return checkRegexp.test(file.name);
    });
  }

  sendFiles(files) {
    if (this._filesContainIllegalType(files)) {
      toastManager.showToast(
        <div>
          Данный тип файлов не разрешен для загрузки.
        </div>,
        {
          duration: 20000
        }
      );
      return;
    }
    this._sendFiles(files, ChatMessageType.FILE);
  }

  sendImages(images) {
    this._sendFiles(images, ChatMessageType.IMAGE);
  }

  deleteMessage(id) {
    this._deleteMessage(id);
  }

  findChatLastMessage(chatId, counter = 1) {
    const chatData = this.chatsData[chatId];
    if (!chatData) {
      return;
    }
    const lastMessage = chatData.messages[chatData.messages.length - counter];
    if (lastMessage?.type === ChatMessageType.SYSTEM) {
      return this.findChatLastMessage(chatId, counter + 1);
    }
    return lastMessage;
  }

  *_deleteMessage(id) {
    const messageIndex = this.currentChatData.messages.findIndex(m => m.id === id);
    const isLastMessage = messageIndex === this.currentChatData.messages.length - 1;
    const [messageToDelete] = this.currentChatData.messages.splice(messageIndex, 1);

    this._updateCurrentChatMessages();
    if (messageToDelete?._status === MessageStatus.ERROR) {
     return;
    }
    try {
      yield apiv2.chat.deleteMessage(id);

      if (isLastMessage && this.currentChat) {
        this.currentChat.last_message = this.findChatLastMessage(this.currentChatId);
        this._updateChats();
      }
    } catch(e) {
      toastManager.showToast(
        <div>
          Не удалось удалить сообщение с ID [{this.currentChatId}] произошла неизвестная ошибка, вы можете написать в поддержку, сообщив код ошибки: {e.code}
        </div>,
        {
          duration: 20000
        }
      );
    }
  }

  get currentChatData() {
    return this.chatsData[this.currentChatId];
  }

  setEditingMessage(id) {
    if (!this.currentChatData) {
      return;
    }
    if (this.currentChatData.editingMessageId === id) {
      return;
    }
    this.currentChatData.editingMessageId = id;
    if (id) {
      this.setMessageText(this.editingMessage.text, true);
    } else if (this.currentChatData) {
      this.setMessageText(this.currentChatData.prevText);
      this.currentChatData.prevText = "";
    }
  }

  get editingMessage() {
    return this.currentChatMessages.find(m => m.id === this.currentChatData?.editingMessageId);
  }

  *sendSystemMessage(type) {
    // ToDo !!! нужно доделывать на бекенде
    const data = {
      receiver_id: this.currentChatId,
      receiver_model_name: "ChatRoom",
      data: { type: type },
      type: ChatMessageType.SYSTEM,
      text: type
    };
    const response = yield apiv2.chat.sendMessage(data);
    this.addMessage(response, this.currentChatId);
  }

  _prepareChats(chats) {
    chats.forEach(chat => {
      chat._type = chat.type === "private_patient" ? ChatTypes.CHAT : ChatTypes.FOLDER;
      chat.opponent.image = chat.opponent.image || chat.image;
      if (chat.last_message) {
        if (chat.last_message.type !== ChatMessageType.SYSTEM) {
          this._prepareMessage(chat.last_message);
          this._checkAndPrepareAttachmentMessage(chat.last_message);
        } else {
          delete chat.last_message;
        }
      }
    });
    return chats;
  }

  _setChatsPaginationData(pagination, currentPage = 1) {
    this.chatsPagination.currentPage = currentPage;
    this.chatsPagination.nextPage = pagination.next_page;
    this.chatsPagination.totalPageCount = pagination.total_pages;
  }

  get chatsSearchString() {
    return this.searchText.split(' ').join('-');
  }

  *getChats(page) {
    try {
      this.isChatsLoading = true;
      const response = yield apiv2.chatroom.get(this.chatsPagination.perPage, page, this.chatsSearchString, this.selectedFilter);
      response.results = response.results.filter((item) => item.opponent && item.opponent.first_name && item.opponent.last_name);
      this._setChatsPaginationData(response.pagination, page);
      return response.results;
    } catch(e) {
      if (!e.code) {
        throw e;
      }
      if (e.code === 20) { // Отмена запроса пользователем
        throw e;
      }
      toastManager.showToast(
        <div>
          При загрузке чатов произошла неизвестная ошибка, вы можете написать в поддержку, сообщив код ошибки: {e.code}
        </div>,
        {
          duration: 20000
        }
      );
      throw e;
    } finally {
      this.isChatsLoading = false;
    }
  }

  *loadChats() {
    try {
      const newChats = yield this.getChats(1);
      this.chats = this._prepareChats(newChats);
      if (!this.isChatsLoaded) {
        this.isChatsLoaded = true;
      }
    } catch(e) {
      void e;
    }
  }

  *updateChats() {
    if (this.isChatsLoading) {
      return;
    }
    if (this.chatsPagination.currentPage >= this.chatsPagination.totalPageCount) {
      return;
    }
    try {
      const newChats = yield this.getChats(this.chatsPagination.nextPage);

      const currentChatIDs = this.chats.map(chat => chat.id);
      const responseChatIDs = newChats.map(chat => chat.id);

      const newChatsIds = _.difference(responseChatIDs, currentChatIDs);
      if (newChatsIds.length) {
        const filteredNewChats = this._prepareChats(newChats.filter(chat => newChatsIds.includes(chat.id)))
        this.chats = [...this.chats, ...filteredNewChats];
      }
    } catch(e) {
      void e;
    }
  }

  *loadChatById(chatId) {
    const currentChatIDs = this.chats.map(chat => chat.id);
    if (currentChatIDs.includes(chatId)) {
      return;
    }
    this.isChatsLoading = true;
    try {
      const response = yield apiv2.chatroom.getChatroom({
        chat_id: chatId
      });

      if (response && response.id !== null) {
        const newChats = this._prepareChats([response]);
        this.chats = [...newChats, ...this.chats];
      }
    } catch(e) {
      toastManager.showToast(
        <div>
          При загрузке чата с ID [{chatId}] произошла неизвестная ошибка, вы можете написать в поддержку, сообщив код ошибки: {e.code}
        </div>,
        {
          duration: 20000
        }
      );
    }
    this.isChatsLoading = false;
  }

  _updateChats() {
    this.chats = _.cloneDeep(this.chats);
  }

  // *readMessage(message) {
  //   const chatToUpdate = this.findChat(message.receiver);
  //   if (!chatToUpdate) {
  //     return;
  //   }
  //   try {
  //     yield apiv2.chat.readMessageById(message.id);
  //     chatToUpdate.unread_messages_count = 0;
  //     this._updateChats();
  //     this.updateUnreadChatsCount();
  //   } catch(e) {
  //     console.error(e);
  //   }
  // }

  async readAllMessagesInChat(chatId) {
    const chatToUpdate = this.findChat(chatId);
    if (!chatToUpdate) {
      return;
    }
    try {
      this.chatIsReadingNow = true;
      await apiv2.chat.readAllMessages(chatId);
      chatToUpdate.unread_messages_count = 0;
      this._updateChats();
      this.chatIsReadingNow = false;
      this.setUnreadChatsCount(this.unreadChatsCount - 1);
    } catch(e) {
      console.error(e);
    }
  }

  hasChat(id) {
    return this.chats.findIndex(chat => chat.id === id) > -1;
  }

  setCurrentChatId(id) {
    this.currentChatId = id;
  }

  setCurrentChat(id) {
    this.setCurrentChatId(id);
    if (this.isAnonymousMode) {
      return;
    }
    if (id) {
      this.loadCurrentChatMessages();
      this.setCurrentChatURL(id);
    }
  }

  setPathName(pathname) {
    const currentURL = new URL(window.location.href);
    if (currentURL.pathname !== pathname) {
      currentURL.pathname = pathname;
      window.history.pushState(null, null, currentURL.toString());
    }
  }

  setCurrentChatURL(id) {
    this.setPathName(`/chats/${id}`);
  }

  *changeChatOpenState(chatId, canChat, closingDate) {
    const chatToOpen = this.findChat(chatId);

    if (chatToOpen && chatToOpen.opponent && authStore.user?.doctor) {
      try {
        yield apiv2.chat.changeChatOpenState(chatToOpen.opponent.patient_id, canChat, closingDate);
        chatToOpen.can_patient_chat = canChat;
      } catch(e) {
        console.log(e);
        toastManager.showToast(
          <div>
            Не получилось {canChat ? 'открыть' : 'закрыть'} чат. Вы можете написать в поддержку сообщив при этом код ошибки: {e.code}
          </div>,
          {
            duration: 20000
          }
        );
      }
    }
  }

  showCallOpenChatConfirm() {
    this.isCallOpenChatConfirmShowed = true;
  }
  closeCallOpenChatConfirm() {
    this.isCallOpenChatConfirmShowed = false;
  }

  *openChat(chatId, closingDate) {
    yield this.changeChatOpenState(chatId, true, closingDate);
  }

  *closeChat(chatId) {
    yield this.changeChatOpenState(chatId, false);
  }

  closeCurrentChat() {
    this.setCurrentChatId(null);
    this.setPathName(`/chats`);
  }

  *setCallId(callId) {
    if (callId === this.currentCallId) {
      return;
    }
    this.currentCallId = callId;
    try {
      const response = yield apiv2.call.get(callId);
      this.setAnonymousMode(response.start_time, response.end_time);
      this.setCurrentChat(response.room);
      this.showCallSettingsScreen();
    } catch(e) {
      toastManager.showToast(
        <div>
          Произошла ошибка при попытке получить звонок, вы можете написать в поддержку или попробовать перезагрузить страницу.
        </div>,
        {
          duration: 20000
        }
      );
    }
  }

  setSearchText(search) {
    this.searchText = search;
  }

  setAnonymousMode(start, end) {
    start = moment(start).subtract(15, "minutes");
    end = moment(end).add(15, "minutes");
    const callback = () => {
      const now = moment();
      this.allowAnonymousMode = true; //now.isAfter(start) && now.isBefore(end); // чтобы не было лишних проблем при запуске
    }
    setInterval(() => runInAction(callback), 1000);
    callback();
    this.isAnonymousMode = true;
  }

  get currentChat() {
    return this.findChat(this.currentChatId);
  }

  _recalcEnpoints() {
    this.endpoints = this.voxCall.getEndpoints();
  }

  *setCameraId(id) {
    if (this.currentDevices.cameraId === id) {
      return;
    }
    this.currentDevices.cameraId = id;
    if (id !== 0) {
      this.lastSelectedDevices.cameraId = id;
    }
    const cameraManager = Hardware.CameraManager.get();
    if (this.voxCall) {
      yield cameraManager.setCallVideoSettings(this.voxCall, { cameraId: id });
    } else {
      yield cameraManager.setDefaultVideoSettings({ cameraId: id });
    }
  }

  *setMicId(id) {
    if (this.currentDevices.micId === id) {
      return;
    }
    this.currentDevices.micId = id;
    if (id !== 0) {
      this.lastSelectedDevices.micId = id;
    }
    const audioManager = Hardware.AudioDeviceManager.get();
    if (this.voxCall) {
      yield audioManager.setCallAudioSettings(this.voxCall, { inputId: id });
    } else {
      audioManager.setDefaultAudioSettings({ inputId: id });
    }
  }

  *setSpeakerId(id) {
    if (this.currentDevices.speakerId === id) {
      return;
    }
    this.currentDevices.speakerId = id;
    if (id !== 0) {
      this.lastSelectedDevices.speakerId = id;
    }
    const audioManager = Hardware.AudioDeviceManager.get();
    if (this.voxCall) {
      yield audioManager.setCallAudioSettings(this.voxCall, { outputId: id });
    } else {
      audioManager.setDefaultAudioSettings({ outputId: id });
    }
  }

  toggleMinimize() {
    this.videoMinimized = !this.videoMinimized;
  }

  *toggleCamera() {
    this.videoStateChangeInProgress = true;
    const newState = !this.videoEnabled;
    yield this.setCameraId(newState ? this.lastSelectedDevices.cameraId : 0);
    console.log("Camera id:", this.currentDevices.cameraId);
    if (this.voxCall) {
      yield this.voxCall.sendVideo(newState);
    }
    console.log("New videoEnabled state:", newState);
    this.videoEnabled = newState;
    this.videoStateChangeInProgress = false;
  }

  toggleMuteMicrophone() {
    this.microphoneMuteInProgress = true;
    if (this.voxCall) {
      if (this.microphoneMuted) {
        this.voxCall.unmuteMicrophone();
      } else {
        this.voxCall.muteMicrophone();
      }
    }
    this.microphoneMuted = !this.microphoneMuted;
    this.microphoneMuteInProgress = false;
  }

  *callConference() {
    if (this.voxCall) {
      return;
    }
    let voximplantInstance;
    try {
      voximplantInstance = yield voximplant.getInstance();
    } catch(e) {
      captureExceptionToSentry(e);
      return;
    }
    if (!voximplantInstance.connected()) {
      const reconnectResult = yield voximplant.connectVoximplantCloud();
      if (!reconnectResult) {
        return;
      }
    }
    if (!this.audioDevices.length) {
      const deviceInitializationResult = yield this.initDevices();
      if (!deviceInitializationResult) {
        return;
      }
    }
    if (this.videoDevices.length) {
      console.log(this.videoEnabled, this.currentDevices.cameraId);
      if (this.videoEnabled) {
        if (!this.currentDevices.cameraId) {
          console.log("Video enabled but camera not selected");
          this.setCameraId(this.videoDevices[0].id ?? this.lastSelectedDevices.cameraId);
        }
      } else {
        console.log("Video disabled or camera not selected");
        this.setCameraId(0);
      }
    }
    if (this.showMediaPermissionNotDeviceModal || this.showMediaPermissionLockModal) {
      return;
    }
    this.voxCall = voximplantInstance.callConference({
      number: this.currentChatId,
      simulcast: true,
      video: { receiveVideo: true, sendVideo: this.videoEnabled }
    });
    this.callOpponent = this.currentChat?.opponent || null;
    this.voxCall.on(CallEvents.Connected, event => {
      if (!this.isAnonymousMode) {
        try {
          apiv2.chatroom.startCall(this.currentChatId);
        } catch(e) {
          captureExceptionToSentry(e);
          toastManager.showToast(
            <div>
              При попытке начать звонок произошла ошибка, вы можете написать в поддержку, или попробовать обновить страницу.
            </div>,
            {
              duration: 20000
            }
          );
        }
      }
      console.log("VOX Connected", event);
      if (this.microphoneMuted) {
        this.voxCall.muteMicrophone();
      }
    });
    this.voxCall.on(CallEvents.EndpointAdded, event => {
      console.log("VOX EndpointAdded", event);
      event.endpoint.on(EndpointEvents.RemoteMediaAdded, this._recalcEnpoints);
      event.endpoint.on(EndpointEvents.RemoteMediaRemoved, this._recalcEnpoints);
      event.endpoint.on(EndpointEvents.RemoteMediaUpdated, this._recalcEnpoints);
      event.endpoint.on(EndpointEvents.VoiceStart, () => {
        this.setVoiceActive(event.endpoint.id, true);
      });
      event.endpoint.on(EndpointEvents.VoiceEnd, () => {
        this.setVoiceActive(event.endpoint.id, false);
      });
    });
    this.voxCall.on(CallEvents.EndpointRemoved, this._recalcEnpoints);
    this.voxCall.on(CallEvents.Failed, event => {
      console.error("VOX Failed", event);
      captureEventToSentry(event);
      this.setVoximplantIsReconnecting(false);
      this.showVoximlpantErrorModal();
    });
    this.voxCall.on(CallEvents.Disconnected, event => {
      console.error("VOX Disconnected", event);
      captureEventToSentry(event);
    });
    this.voxCall.on(CallEvents.CallStatsReceived, event => {
      console.log(event);
      this.voxCallStats = event.stats;
    });
    console.log("call created: ", this.voxCall);
    // const cameraManager = CameraManager.get();
    // const devices = await cameraManager.getInputDevices();
    // const device = devices.find((device) => device.id === cameraManager.getCallVideoSettings(this.voxCall).cameraId);
    // const constraints = {
    //     audio: true,
    //     video: {
    //         deviceId: device.id,
    //     }
    // };
    // const media = await navigator.mediaDevices.getUserMedia(constraints);
    // media.getVideoTracks();
  }

  setVoiceActive(endpointId, active) {
    this.voiceActive[endpointId] = active;
  }

  hangup() {
    if (this.voxCall.state() !== "ENDED") { // enum не импортнуть и в нем не те значения
      this.voxCall.hangup();
    }
    this.voxCall = null;
    this.voxCallStats = {};
    this.callOpponent = null
    this.videoMinimized = false;
    this.resetCurrentDevices();
  }

  *searchChats() {
    try {
      const newChats = yield this.getChats(1);

      if (newChats) {
        this.chats = this._prepareChats(newChats);
      }
    } catch(e) {
      void e;
    }
  }

  setFilter(filter) {
    this.selectedFilter = filter;
  }

  *startCallForWebView(scheduleId, anonymousLink) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: "callPatient", link: anonymousLink }));
    const chatroom = yield apiv2.chatroom.getByScheduleId(scheduleId);
    if (!chatroom) {
      return;
    }
    apiv2.chatroom.startCall(chatroom.id);
  }

  openSettingChatModal() {
    this.isShowSettingChatsModal = true;
  }

  closeSettingChatModal() {
    this.isShowSettingChatsModal = false;
  }

  async setChatSettings(settings) {
    this.closeSettingChatModal();
    try {
      await apiv2.chatroom.setSettingsChats(settings);
      authStore.updateUser(settings);
    } catch (e) {
      console.error(e);
    }
  }
}

export default new ChatStore();
