import ApiService from "../apiService";
import { TEMPLATE_TEXTS } from "../../stores/voxInformationPatientStore";

const API_URL = process.env.EMS_API_URL || location.origin;
class Communication {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/communication', null, null, API_URL);
  }

  sendDynamicMessage({ messageData = {}, phone_number, template }) {
    return this.sendDynamicMessageWithCustomTemplate({
      messageData,
      phone_number,
      template,
      template_text: TEMPLATE_TEXTS[template]
    });
  }

  sendDynamicMessageWithCustomTemplate({ messageData = {}, phone_number, template, template_text }) {
    return this.apiService.post(`send`, {},
      {
        "message": { "token": template, "template": template_text },
        "channel": "edna",
        "push_title": "Клиника Фомина",
        "allow_another_channel": false,
        "type": "INFO", // CONFIRM, INFO
        "receivers": [
          {
            "phone": phone_number,
            "birthdate": "1997-08-15",
            "message_data": messageData
          }
        ]
      },
      {'SECRETKEY': 'fnihnx=l8xgfsmt12ol%5f#iaemyja2xez7whi!9@4smn_i#9+'},
      true, "", false, "", true
    );
  }

}

export default new Communication();
