import ApiService from "../apiService";
import { tokenStore as eff_tokenStore } from "../../stores/auth";

const uploadProgressHandler = (event) => {
  return Math.round((event.loaded / event.total) * 100);
}

const sendFile = async (formData, attachmentUuid, onProgressUpdate) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();

  xhr.upload.addEventListener('progress', (event) => {
    const loadedPercent = uploadProgressHandler(event);
    onProgressUpdate(attachmentUuid, loadedPercent);
  }, false);
  xhr.addEventListener('load', resolve, false);
  xhr.addEventListener('error', reject);

  xhr.open('POST', API_URL + '/api/attachments/');
  xhr.setRequestHeader('APP-TYPE', 'doctor');
  xhr.setRequestHeader('Authorization', `JWT ${eff_tokenStore.getState()}`);

  xhr.send(formData);
});

const API_URL = process.env.EMS_API_URL || location.origin;
class MessageAttachments {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  send(data, attachments, onProgressUpdate) {
    const formData = new FormData();
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          formData.append(key, item);
        })
      } else {
        formData.append(key, data[key]);
      }
    }
    for (let attachment of attachments) {
      formData.set("file_list", attachment.original_file);
      sendFile(formData, attachment.uuid, onProgressUpdate);
    }
    // return this.apiService.post("attachments", {}, formData, {}, false);
  }
}

export default new MessageAttachments();
