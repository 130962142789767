import React, { useState, useRef } from "react";
import styled from "styled-components";
import { ClickAwayListener, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { withModifiers } from "with-modifiers";

const CustomTooltipContainer = withModifiers({
  increaseSpecificity: () => `
    display: block;
    z-index: 1;
  `
})(
  modifier => styled.div`
    width: ${props => (props.width ? props.width : "inherit")};
    height: inherit;
    box-sizing: border-box;

    ${modifier};
  `
);

/** Если большое количество Tooltip MUI в DOM-дереве, появляется проблема производительности
 * Найдено решение, делать рендер Tooltip только в том случае, если пользователь навел на него
 * https://github.com/mui/material-ui/issues/27057
 * - enablePressDuration - рекомендуется включить, если у дочернего элемента есть событие onClick или ссылка
 * Прокидывать enableExternalHook, isExternalRenderTooltip и setIsExternalRenderTooltip при использовании Link.
 * - Это необходимо для обработки кейса с потерей контекста тултипа при 1-ом переходе по роутингу
 * - Происходит временное "отсоединение" DOM-элемента и тултип улетает в позиционирование (0,0) */
export const CustomMUITooltip = ({
  width,
  longPressDuration = 1000,
  enablePressDuration = false,
  modifiers,
  enableExternalHook = false,
  isExternalRenderTooltip,
  setIsExternalRenderTooltip,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const isMobileDevices = useMediaQuery(theme.breakpoints.down("md"));
  const [renderTooltip, setRenderTooltip] = useState(false);
  const pressTimer = useRef(null);
  const isRenderTooltip = enableExternalHook ? isExternalRenderTooltip : renderTooltip;

  const handleOnOpen = () => {
    if (isMobileDevices && enablePressDuration) {
      return;
    }
    if (enableExternalHook) {
      setIsExternalRenderTooltip(true);
    } else {
      setRenderTooltip(true);
    }
  };
  const handleOnClose = () => {
    if (enableExternalHook) {
      setIsExternalRenderTooltip(false);
    } else {
      setRenderTooltip(false);
    }
  };

  const handleOnMouseEnter = () => {
    if (isMobileDevices) {
      return;
    }
    handleOnOpen();
  };
  const handleOnMouseLeave = () => {
    if (isMobileDevices) {
      return;
    }
    handleOnClose();
  };

  const handleOnStartTouch = () => {
    if (!isMobileDevices || !enablePressDuration) {
      return;
    }
    clearPressTimer();
    pressTimer.current = setTimeout(() => {
      if (enableExternalHook) {
        setIsExternalRenderTooltip(true);
      } else {
        setRenderTooltip(true);
      }
    }, longPressDuration);
  };
  const clearPressTimer = () => {
    if (!isMobileDevices || !enablePressDuration) {
      return;
    }
    clearTimeout(pressTimer.current);
  };

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <CustomTooltipContainer
        className="custom-tooltip"
        onClick={handleOnOpen}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onTouchStart={handleOnStartTouch}
        onTouchMove={clearPressTimer}
        onTouchEnd={clearPressTimer}
        onTouchCancel={clearPressTimer}
        width={width}
        modifiers={modifiers}>
        {!isRenderTooltip && children}
        {isRenderTooltip && (
          <Tooltip open={isMobileDevices ? isRenderTooltip : undefined} {...rest}>
            {children}
          </Tooltip>
        )}
      </CustomTooltipContainer>
    </ClickAwayListener>
  );
};
