import { makeAutoObservable } from 'mobx';
import {
  currentUser as eff_currentUser,
} from './auth';
import { UPDATE_NEWS_LINK } from "../version";
import commonStore from "./commonStore";

export const TABS = {
  SCHEDULE: "schedule",
  HISTORY: "history",
  ACCOUNT: "account",
  FEEDBACK: "feedback",
  MY_PATIENTS: "my_patients",
  GENERAL_SCHEDULE: "general_schedule",
  GENERAL_SCHEDULE_NEW: "general_schedule_new",
  HOSPITAL: "hospital",
  CHATS: "chats",
  UPDATE_NEWS: "update_news",
  PROFILE: "profile",
  MORE_NAVIGATION_TABS: "more_navigation_tabs",
  SUPPORT: 'support',
  EXIT: "exit",
  PATIENTS: 'patients',
  CORPORATE_PORTAL: 'corporate_portal',
  REVIEW_STATISTICS: 'review_statistics',
}

export const TABS_NAME = {
  [TABS.SCHEDULE]: "Мое расписание",
  [TABS.HISTORY]: "История приемов",
  [TABS.ACCOUNT]: "Профиль",
  [TABS.FEEDBACK]: "Обратная связь",
  [TABS.MY_PATIENTS]: "Исходы программы ВРТ",
  [TABS.GENERAL_SCHEDULE]: "Общее расписание",
  [TABS.GENERAL_SCHEDULE_NEW]: "Новое общее расписание",
  [TABS.HOSPITAL]: "Стационар",
  [TABS.CHATS]: "Чаты",
  // [TABS.UPDATE_NEWS]: "Что нового",
  [TABS.PROFILE]: "Профиль",
  [TABS.SUPPORT]: "Поддержка",
  [TABS.MORE_NAVIGATION_TABS]: "Еще",
  [TABS.PATIENTS]: "Справочник пациентов",
  [TABS.REVIEW_STATISTICS]: "Отзывы за баллы",
}

export const TABS_URL = {
  [TABS.SCHEDULE]: "/schedule",
  [TABS.HISTORY]: "/schedule/history",
  [TABS.ACCOUNT]: "/account",
  [TABS.FEEDBACK]: "/schedule/feedback",
  [TABS.MY_PATIENTS]: "/my-patients",
  [TABS.GENERAL_SCHEDULE]: "/general-schedule",
  [TABS.GENERAL_SCHEDULE_NEW]: "/general-schedule-new",
  [TABS.HOSPITAL]: "/schedule/hospital",
  [TABS.CHATS]: "/chats",
  // [TABS.UPDATE_NEWS]: UPDATE_NEWS_LINK,
  [TABS.PROFILE]: "/profile",
  [TABS.MORE_NAVIGATION_TABS]: "/more-navigation-tabs",
  [TABS.PATIENTS]: "/patients",
  [TABS.CORPORATE_PORTAL]: "/corporate-portal",
  [TABS.REVIEW_STATISTICS]: "/review-statistics",
}

class HeaderStore {
  activeTab = 'schedule';
  lastMainNavigationTab = TABS.SCHEDULE;
  isNurse = false;
  isHospitalApproved = false;
  outcomesExists = false;
  drawerIsCollapsed = false;

  constructor() {
    makeAutoObservable(this);

    this.setActiveTab = this.setActiveTab.bind(this);

    eff_currentUser.watch(user => { // Подписываемся на изменения пользователя в старой сторе
      this.setIsHospitalApproved(
        Boolean(
          user &&
          ((user.role === "doctor" &&
              user.doctor &&
              user.doctor.specialities &&
              Boolean(user.doctor.specialities.find(item => item.name === "Хирург"))) ||
            user.role === "post_nurse")
        )
      );

      this.setOutcomesExists(Boolean(user && (user.role === "doctor" && user.doctor && user.doctor.outcomes_exists)));
      this.setIsNurse(Boolean(user && user.role === "post_nurse"));
    });
  }

  isShowNavigationTab(isMobileDevice) {
    if (!isMobileDevice) {
      return !this.isNurse;
    } else {
      return !this.isNurse && [
        TABS.HISTORY,
        TABS.SCHEDULE,
        TABS.GENERAL_SCHEDULE,
        TABS.GENERAL_SCHEDULE_NEW,
        TABS.CHATS,
        TABS.MORE_NAVIGATION_TABS
      ].includes(this.activeTab)
    }
  }

  setActiveTab(tab) {
    if (tab === this.activeTab) {
      return;
    }
    if ([TABS.HISTORY, TABS.SCHEDULE, TABS.GENERAL_SCHEDULE, TABS.CHATS, TABS.MORE_NAVIGATION_TABS].includes(tab)) {
      this.setLastMainNavigationTab(tab);
    }
    this.activeTab = tab;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ isNavigationVisible: [
          TABS.HISTORY,
          TABS.SCHEDULE,
          TABS.GENERAL_SCHEDULE,
          TABS.GENERAL_SCHEDULE_NEW,
          TABS.CHATS,
          TABS.MORE_NAVIGATION_TABS
          ].includes(this.activeTab) })
      );
    }
  }

  setLastMainNavigationTab(tab) {
    this.lastMainNavigationTab = tab;
  }

  setActiveTabByLocationPath(path) {
    const activeTab = Object.keys(TABS_URL).filter((tab) => path.includes(TABS_URL[tab]));
    this.setActiveTab(activeTab.length === 1 ? activeTab[0] : activeTab[1]);
  }

  setIsHospitalApproved(value) {
    this.isHospitalApproved = value;
  }

  setIsNurse(value) {
    this.isNurse = value;
  }

  setOutcomesExists(value) {
    this.outcomesExists = value;
  }

  handlerChangeTab(tab, history) {
    if (tab === TABS.EXIT) {
      return;
    }
    const remoteURLReg = /https?:/;
    const url = TABS_URL[tab];
    if (remoteURLReg.test(url)) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ event: "openLink", link: url }));
      } else {
        window.open(url, "_blank");
      }
      if (tab === TABS.UPDATE_NEWS) {
        commonStore.changeIsShowedUpdateVersion(true);
      }
    } else {
      this.setActiveTab(tab);
      window.scrollTo(0, 0);
      history.push(TABS_URL[tab]);
    }
  }

  handlerOnCollapseDrawer(flag) {
    this.drawerIsCollapsed = flag;
  }
}

export default new HeaderStore();
