export const restartVoximplantSupport = () => {
  window.VoxKitWidgetSettings.client_data = {
    client_id: '', // Customer ID
    client_phone: '', // Customer phone number
    client_avatar: ''  , // Customer avatar link
    client_display_name: '',  // Customer name
    client_email: '', // Customer email
    client_language: 'ru' // Interface language: 'en' or 'ru'
  }
  if (window.VoxKitWidget) {
    if (document.getElementsByClassName('kit-widget-container is-open').length) {
      document.getElementsByClassName('widget-btn')[0].click();
    }
    reInitVoximplant();
  }
};

export const reInitVoximplant = () => {
  localStorage.removeItem('kit_chat_open');
  localStorage.setItem('kit_client_id', window.VoxKitWidgetSettings.client_data.client_id);
  // if (!window.ReactNativeWebView) {
  //   window.location.reload();
  // }
}

export const setVoximplantSupportData = (data, overwrite = false, overwriteCustomData) => {
  if (overwrite) {
    window.VoxKitWidgetSettings.client_data = {...data, client_email: JSON.stringify(data.client_email)};
  } else {
    let client_email = window.VoxKitWidgetSettings.client_data.client_email; // Подаю сюда кастомные данные, чтоб в поддержке можно было увидеть.
    if (data.client_email) {
      const parseData = client_email && client_email !== '{}' ? JSON.parse(client_email) : {};
      if (overwriteCustomData) {
        client_email = JSON.stringify({
          page: parseData.page || location.href,
          ...data
        });
      } else {
        client_email = client_email && client_email !== '{}' ? JSON.stringify({
            ...parseData,
            page: parseData.page || location.href,
            ...JSON.parse(typeof data.client_email === 'string' ? data.client_email : JSON.stringify(data.client_email)),
          }) : JSON.stringify({...data.client_email})
      }
    }
    window.VoxKitWidgetSettings.client_data = {...window.VoxKitWidgetSettings.client_data, ...data, client_email: client_email};
  }
};