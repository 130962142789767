import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Integrations {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/integration', null, null, API_URL);
  }

  getCommunication(phone_number) {
    return this.apiService.post(`communication`, {}, { phone_number: phone_number }, {'Secretkey': 'secret'});
  }
  getMedicalFiles(phone_number) {
    return this.apiService.post(`medical_files`, {}, { phone_number: phone_number }, {'Secretkey': 'secret'});
  }

  getOrganizations() {
    return this.apiService.get(`organizations`, {}, {'Secretkey': 'secret'});
  }

  getScheduleFuture(phone_number) {
    return this.apiService.post(`schedule/future`, {}, { phone_number: phone_number }, {'Secretkey': 'secret'});
  }
  getSpecialities() {
    return this.apiService.get(`specialities`, {limit: 10000}, {'Secretkey': 'secret'});
  }

  getActiveCity(phone_number) {
    return this.apiService.get(`phone-number/${phone_number}`, {}, {'Secretkey': 'secret'});
  }

  setActiveCity(phone_number, data) {
    return this.apiService.post(`phone-number/${phone_number}`, {}, data, {'Secretkey': 'secret'});
  }

}

export default new Integrations();
