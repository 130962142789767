import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class MedicalFilesV2 {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api-v2', null, null, API_URL);
  }

  get({
        patientId
      }) {
    return this.apiService.get(`medical-files`, {
      patient_id: patientId
    }, {}, true, '');
  }
}

export default new MedicalFilesV2();
